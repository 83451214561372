<template>
  <div class="content_body" v-loading="loading">
    <div class="nav_header">
      <el-row>
        <el-col :span="20">
          <el-form :inline="true" size="small">
            <el-form-item label="包装单位">
              <el-input v-model="Name" placeholder="输入包装单位搜索" clearable @clear="handleSearch" @keyup.enter.native="handleSearch"></el-input>
            </el-form-item>
            <el-form-item label="有效性">
              <el-select v-model="Active" placeholder="请选择有效性"  @change="handleSearch" clearable>
                <el-option label="是" :value="true"></el-option>
                <el-option label="否" :value="false"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="handleSearch" v-prevent-click>搜索</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4" class="text_right">
          <el-button type="primary" size="small" @click="showAddDialog" v-prevent-click>新增</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="martp_10">
      <el-table size="small" :data="tableData">
        <el-table-column prop="Name" label="单位名称"></el-table-column>
        <el-table-column prop="Active" label="有效性" :formatter="formatStatus"></el-table-column>
        <el-table-column label="操作" width="80">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              @click="showEditDialog(scope.row)"
              v-prevent-click
            >编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <div class="pad_15 text_right">
        <el-pagination
          background
          v-if="paginations.total > 0"
          @current-change="handleCurrentChange"
          :current-page.sync="paginations.page"
          :page-size="paginations.page_size"
          :layout="paginations.layout"
          :total="paginations.total"
        ></el-pagination>
      </div>
    </div>

    <!--增加、编辑弹窗-->
    <el-dialog title="包装单位" :visible.sync="dialogVisible" width="30%">
      <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="120px" @submit.native.prevent>
        <el-form-item label="单位名称" prop="Name">
          <el-input size="small" v-model="ruleForm.Name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="是否有效" v-if="modalTitle=='编辑'" prop="Active">
          <el-radio-group v-model="ruleForm.Active">
            <el-radio :label="true">是</el-radio>
            <el-radio :label="false">否</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small" v-prevent-click>取 消</el-button>
        <el-button
          type="primary"
          @click="addSubmit"
          :loading="modalLoading"
          size="small"
          v-prevent-click
        >保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import API from "@/api/PSI/Product/unit";

export default {
  name: "ProductUnit",
  data() {
    return {
      loading: false,
      modalLoading: false,
      Name: "", // 搜索框数据
      Active: true, // 有效性绑定值
      tableData: [], // 表格数据
      dialogVisible: false, //新增弹框状态
      modalTitle: "新增", // 弹出框判断码
      updateId: "", // 编辑信息的ID值
      ruleForm: {
        Name: "",
        JobDescription: "",
        Active: ""
      }, // 表单数据
      rules: {
        Name: [{ required: true, message: "请输入单位名称", trigger: "blur" }],
        JobDescription: [
          { required: true, message: "请输入职责说明", trigger: "blur" }
        ],
        Active: [
          { required: true, message: "请选择是否有效", trigger: "change" }
        ]
      }, // 表单验证规则
      paginations: {
        page: 1, // 当前位于哪页
        total: 100, // 总数
        page_size: 12, // 1页显示多少条
        layout: "total, prev, pager, next,jumper" // 翻页属性
      } //需要给分页组件传的信息
    };
  },
  methods: {
    // //状态显示转换
    formatStatus: function(row) {
      return row.Active ? "是" : "否";
    },
    // 数据显示
    handleSearch: function() {
      var that = this;
      that.paginations.page = 1;
      that.getUnitList();
    },
    // 获取包装单位列表
    getUnitList: function() {
      var that = this;
      that.loading = true;
      var params = {
        Name: that.Name,
        Active: that.Active,
        PageNum: that.paginations.page
      };
      API.getUnit(params)
        .then(res => {
          if (res.StateCode == 200) {
            that.tableData = res.List;
            that.paginations.total = res.Total;
            that.paginations.page_size = res.PageSize;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000
            });
          }
        })
        .finally(function() {
          that.loading = false;
        });
    },
    // 上下分页
    handleCurrentChange(page) {
      var that = this;
      that.paginations.page = page;
      that.getUnitList();
    },
    // 新增按钮点击事件
    showAddDialog: function() {
      var that = this;
      that.dialogVisible = true;
      that.modalTitle = "新增";
      that.ruleForm.Name = "";
    },
    // 编辑按钮点击事件
    showEditDialog: function(row) {
      var that = this;
      that.dialogVisible = true;
      that.modalTitle = "编辑";
      that.ruleForm.Name = row.Name;
      that.ruleForm.Active = row.Active;
      that.updateId = row.ID;
    },
    //模态窗数据
    addSubmit: function() {
      let that = this;
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (that.modalTitle == "新增") {
            that.createUnit();
          } else {
            that.updateUnit();
          }
        }
      });
    },
    // 创建包装单位
    createUnit: function() {
      var that = this;
      that.modalLoading = true;
      var params = {
        Name: that.ruleForm.Name
      };
      API.createUnit(params)
        .then(res => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.$refs["ruleForm"].resetFields();
            that.dialogVisible = false;
            that.getUnitList();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000
            });
          }
        })
        .finally(function() {
          that.modalLoading = false;
        });
    },
    // 编辑更新包装单位
    updateUnit: function() {
      var that = this;
      that.modalLoading = true;
      var params = {
        Name: that.ruleForm.Name,
        ID: that.updateId,
        Active: that.ruleForm.Active
      };
      API.updateUnit(params)
        .then(res => {
          if (res.StateCode == 200) {
            that.$message.success("成功");
            that.$refs["ruleForm"].resetFields();
            that.dialogVisible = false;
            that.getUnitList();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000
            });
          }
        })
        .finally(function() {
          that.modalLoading = false;
        });
    }
  },
  mounted() {
    var that = this;
    that.getUnitList();
  }
};
</script>

<style scoped lang="scss">
</style>

